import React, { Fragment } from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { MDBAnimation } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"

class ColumnOverlayLogos extends React.Component {
  render() {
    return (
      <>
        
          <MDBCol lg="12">
            <MDBAnimation reveal type="fadeInUp">
              <div className="features-absolute rounded bg-white">
                <MDBRow>
                  <MDBCol lg="12" md="12" className="my-4">
                    <MDBRow className="justify-content-center">
                      <MDBCol md="12">
                        <div className="d-flex p-4">
                          <div className="flex-1">
                            <p
                              className="font-alt font-w-200 text-center title-xs-small title-large mb-xl-2 mx-3"
                              dangerouslySetInnerHTML={{
                                __html: this.props.logobox.logoboxtitle,
                              }}
                            />
                          </div>
                        </div>
                      </MDBCol>

                      <MDBCol md="12">
                        <div className="d-flex">
                          <div className="flex-1">
                            <h2
                              className="font-w-700 text-center font-alt mb-4 title-xs-large mx-3 letter-spacing-1"
                              dangerouslySetInnerHTML={{
                                __html: this.props.logobox.logoboxsubtitle,
                              }}
                            />
                          </div>
                        </div>
                      </MDBCol>

                      {this.props.logobox.logo.map((logos, index) => {
                        return (
                          <Fragment key={index}>
                            <MDBCol lg="6" md="8" className="mt-3">
                              <div className="p-4">
                                  <GatsbyImage
                                    image={ logos.logoboximage.childImageSharp.gatsbyImageData }
                                    className="mt-2 text-center"
                                    alt={logos.logoboxalttext}
                                  />
                                </div>
                            </MDBCol>
                          </Fragment>
                        )
                      })}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBAnimation>
          </MDBCol>
      </>
    )
  }
}

export default ColumnOverlayLogos
